import React, { useState } from 'react';
import { TextField, Button, Grid, Box, Typography } from '@mui/material';
import { SavedIcon, CheckIcon, FullViewIcon, SaveIcon } from '../assets/icons/hiking';

export default function Testing() {


    const [formData, setFormData] = useState({
        date_created_gmt: '2024-09-01T14:52:21',
        order_id: '12345',
        address_index: '55555555',
        live_test: '0',
        shipping_total: '1.0',
        line_items: [
            {
                sku: '',
                quantity: '',
                price: ''
            }
        ]
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleLineItemChange = (index, e) => {
        const { name, value } = e.target;
        const newLineItems = [...formData.line_items];
        newLineItems[index][name] = value;
        const eid = localStorage.getItem("default");
        setFormData({
            ...formData,
            line_items: newLineItems,
        });
    };

    const handleSubmit = (e) => {
        const payload = {
            live_test: '0',
            customerCode: "320000691",
            itemCode: "101-110-00129",
            currency: "USD",
            salesOffice: '',
            site: "D_323100"
        };

        fetch('https://emea.trcbridge.com/ntt_price_lookup.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams(payload)
        })
            .then(response => response.text())
            .then(price => {
                console.log('Price:', price);
                document.getElementById('priceDisplay').innerText = price;
            })
            .catch(error => {
                console.error('Error fetching price:', error);
            });

    };





    return (
        <div>
            <Box component="form" sx={{ p: 4, backgroundColor: '#f9f9f9', borderRadius: 2 }}>

                <button onClick={handleSubmit}>
                    get price
                </button>


            </Box>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mr-5 w-full">
                {Array(10).fill().map((_, index) => (
                    <div key={index} style={{ textAlign: 'center', position: 'relative', width: '264px', margin: 'auto' }}>
                        <div className="blur-on-hover relative" style={{ backgroundColor: 'rgba(255, 255, 255)', width: '264px', height: '307px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
                            <img src={`productImage${index + 1}.jpg`} alt={`Producto ${index + 1}`} style={{ maxWidth: '220px' }} />
                            <div style={{ position: 'absolute', bottom: '10px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 10px', boxSizing: 'border-box' }}>
                                <div style={{ borderTop: '1px solid #ccc', width: '100%', display: 'flex', justifyContent: 'space-between', position: 'relative', paddingTop: '5px' }}>
                                    <span style={{ fontSize: '14px', fontWeight: 'bold', padding: '0 5px' }}>100-20-3429</span>
                                    <span style={{ fontSize: '14px', fontWeight: 'bold', padding: '0 5px' }}>item 200</span>
                                </div>
                            </div>
                            <button className="add-to-cart-button font-semibold opacity-0 text-sm" style={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2 }}>
                                Add to Cart
                            </button>
                            <button className="add-to-cart-button font-semibold opacity-0 text-sm" style={{ position: 'absolute', top: '55%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2 }} onClick={() => window.location.href = `/app/product?sku=SKU-00${index + 1}`}>
                                View Product
                            </button>
                        </div>

                        <div style={{ textAlign: 'left', marginTop: '10px', width: '264px', margin: 'auto' }}>
                            <span className="font-semibold text-sm block truncate">Product Title</span>
                            <span className="font-medium text-sm block truncate">Short Description</span>

                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <span style={{ fontWeight: '700', fontSize: '20px' }}>€200.00</span>
                                <div className="cursor-pointer" style={{ position: 'relative' }}>
                                    <SaveIcon />
                                    {/* {
                                        savedNotificationProductId === product.id && (
                                            <div style={{ position: 'absolute', top: '-30px', right: '0px', backgroundColor: 'black', color: 'white', padding: '2px 4px', borderRadius: '5px', zIndex: '50', fontSize: '12px' }}>
                                                Saved
                                            </div>
                                        )
                                    } */}
                                </div>
                            </div>
                        </div>

                    </div>
                ))}
            </div>

        </div>
    );
}
