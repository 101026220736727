import React, { useState } from 'react';
import { motion } from "framer-motion";
import { NextIcon, PreviousIcon } from '../assets/icons/hiking';
import { navigate } from "hookrouter";


export default function Plans() {



  const getImagesToShow = (imagesArray, currentIndex) => {
    const extendedImages = [...imagesArray, ...imagesArray];
    return extendedImages.slice(currentIndex, currentIndex + 3);
  };

  const getDescriptionsToShow = (descriptionsArray, currentIndex) => {
    const extendedDescriptions = [...descriptionsArray, ...descriptionsArray];
    return extendedDescriptions.slice(currentIndex, currentIndex + 3);
  };


  //array 1
  const [currentIndex1, setCurrentIndex1] = useState(0);
  const images1 = ['https://en.techeurope.com/wp-content/uploads/2021/05/TECH-Europe-TECH-Tire-Repairs-250UL-UL6-Plug-Patch-Combination-Repair.png', 'https://en.techeurope.com/wp-content/uploads/2021/07/165-CT10-TECH-Radial-Repair-.png', 'https://en.techeurope.com/wp-content/uploads/2021/03/TECH-Europe-TECH-Tire-Repairs-215PK-Permacure-Kit.png', '/wheel.png', '/trc-one.png', '/trc-model.png', '/trc-model2.png'];
  const description1 = ['One-Piece & Stem Repairs', 'Radial Repairs', 'Permacure'];

  const nextImage1 = () => {
    setCurrentIndex1((currentIndex1 + 1) % images1.length);
  };

  const prevImage1 = () => {
    setCurrentIndex1((currentIndex1 - 1 + images1.length) % images1.length);
  };




  //array 2
  const [currentIndex2, setCurrentIndex2] = useState(0);

  const images2 = ['https://en.techeurope.com/wp-content/uploads/2019/06/TECH-Europe-TECH-Tire-Repairs-720-Rim-Ease.png', 'https://en.techeurope.com/wp-content/uploads/2021/03/TECH-Europe-TECH-Tire-Repairs-760-Vulcanizing-Fluid.png', 'https://en.techeurope.com/wp-content/uploads/2019/06/TECH-Europe-TECH-Tire-Repairs-704A.png'];
  const description2 = ['Mounting & Demounting', 'Vulcanizing Fluids & Cements', 'Rubber Cleaners'];


  const nextImage2 = () => {
    setCurrentIndex2((currentIndex2 + 1) % images2.length);
  };

  const prevImage2 = () => {
    setCurrentIndex2((currentIndex2 - 1 + images2.length) % images2.length);
  };




  //array 3
  const [currentIndex3, setCurrentIndex3] = useState(0);

  const images3 = ['https://en.techeurope.com/wp-content/uploads/2021/07/TECHS891.png', 'https://en.techeurope.com/wp-content/uploads/2021/07/1009.png', 'https://en.techeurope.com/wp-content/uploads/2021/07/BB06l-Bead-Bazooka-300x300.jpg'];
  const description3 = ['Brushes', 'Air Tools & Accessories', 'Workshop Equipment'];


  const nextImage3 = () => {
    setCurrentIndex3((currentIndex3 + 1) % images3.length);
  };

  const prevImage3 = () => {
    setCurrentIndex3((currentIndex3 - 1 + images3.length) % images3.length);
  };




  //array 4
  const [currentIndex4, setCurrentIndex4] = useState(0);

  const images4 = ['https://en.techeurope.com/wp-content/uploads/2021/07/L-Ring-Seal-Earthmover-Rings-TECH-Tire-Repairs-300x300.jpg', 'https://en.techeurope.com/wp-content/uploads/2021/07/BLE133TP-300x300.png', 'https://en.techeurope.com/wp-content/uploads/2021/07/Tech_2-300x300.png'];
  const description4 = ['O & L Rings', 'Valves & Valve Hardware', 'Wheel Balancing'];


  const nextImage4 = () => {
    setCurrentIndex4((currentIndex4 + 1) % images4.length);
  };

  const prevImage4 = () => {
    setCurrentIndex4((currentIndex4 - 1 + images4.length) % images4.length);
  };




  const imageDisplay1 = getImagesToShow(images1, currentIndex1);
  const descriptionDisplay1 = getDescriptionsToShow(description1, currentIndex1);

  const imageDisplay2 = getImagesToShow(images2, currentIndex2);
  const descriptionDisplay2 = getDescriptionsToShow(description2, currentIndex2);

  const imageDisplay3 = getImagesToShow(images3, currentIndex3);
  const descriptionDisplay3 = getDescriptionsToShow(description3, currentIndex3);

  const imageDisplay4 = getImagesToShow(images4, currentIndex4);
  const descriptionDisplay4 = getDescriptionsToShow(description4, currentIndex4);

  const username = localStorage.getItem("username");

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex flex-col justify-center items-center pt-4"
    >

      <header className="text-center mb-4">
        <h1 className="text-xl font-semibold">
          Welcome {username}, to{' '}
          <a href="/app/welcome" className="underline" style={{ color: 'rgb(220, 38, 38)' }}>
            TRC-ONE
          </a>
        </h1>
        <p className="text-normal mt-1 mx-auto" style={{ maxWidth: '1000px', padding: '0 20px' }}>
          TECH Europe is proud to announce the launch of our online portal. Whether it's tire repair materials, retreading equipment or workshop tools you need, TECH Europe has you covered!
        </p>
      </header>

      <div className="flex flex-col md:flex-row justify-center items-center w-full mb-4 px-4 md:px-6">
        <div className="contenedor-imagen relative mb-4 md:mb-0 md:mr-4">
          <img src={`${process.env.PUBLIC_URL}/trc-model.png`} alt="Model 1" className="imagen-con-borde" />
          <p className="contenedor-texto">Stock Clearance</p>
          <p className="description-card">30% off on stock clearance</p>
          <button className='button-card'>EXPLORE ITEMS</button>
        </div>

        <div className="contenedor-imagen relative">
          <img src={`${process.env.PUBLIC_URL}/trc-model2.png`} alt="Model 2" className="imagen-con-borde" />
          <p className="contenedor-texto">New Arrivals</p>
          <p className="description-card">30% off on new arrivals</p>
          <button className='button-card'>EXPLORE ITEMS</button>
        </div>
      </div>




      {/*array 1*/}
      <div className="flex flex-col w-full mt-8 items-center px-4">
        <div className="flex flex-col md:flex-row justify-center w-full max-w-7xl mx-auto">
          <div className="flex flex-col w-full md:w-1/3 p-4">
            <h2 className="text-2xl font-bold mb-4">Tire Repairs</h2>
            <p className="mb-4">
              TECH Tire Repairs has a rich history of innovation and quality, spanning over eight decades. Our tire repair solutions are not only widely accepted but also adhere to stringent global standards, ensuring safety and reliability. With a commitment to enhancing tire longevity and customer trust...
            </p>
            <button className="w-full text-black font-semibold py-2 rounded border border-black cursor-pointer" onClick={() => navigate('tire-repairs')}>
              BROWSE ALL
            </button>
            <div className="flex py-4 px-2">
              <div className="cursor-pointer mr-2" onClick={prevImage1}><PreviousIcon /></div>
              <div className="cursor-pointer" onClick={nextImage1}><NextIcon /></div>
            </div>
          </div>

          <div className="flex overflow-x-auto space-x-4 p-4">
            {imageDisplay1.map((src, index) => (
              <div key={index} className="bg-white shadow-md flex-none p-2 m-2 flex flex-col items-center" style={{ width: '256px', height: '256px' }}>
                <img src={src} className="mb-2 w-full h-full object-cover" />
                <p className="text-center font-bold"><br></br>{descriptionDisplay1[index]}</p>
              </div>
            ))}
          </div>
        </div>
        <hr className="w-full border-gray-200 border-2 m-0" />
      </div>



      {/* array 2 */}
      <div className="flex flex-col w-full mt-8 items-center px-4">
        <div className="flex flex-col md:flex-row justify-center w-full max-w-7xl mx-auto">
          <div className="flex flex-col w-full md:w-1/3 p-4">
            <h2 className="text-2xl font-bold mb-4">Chemicals</h2>
            <p className="mb-4">
              The chemical vulcanization process, a significant advancement in the rubber industry, was pioneered by TECH over eight decades ago. This process, which involves heating rubber with sulfur, transforms the physical properties of rubber, enhancing its strength, elasticity...
            </p>
            <button
              onClick={() => navigate('chemicals')}
              className="w-full text-black font-semibold py-2 rounded border border-black cursor-pointer">
              BROWSE ALL
            </button>
            <div className="flex py-4 px-2">
              <div className="cursor-pointer mr-2" onClick={prevImage2}><PreviousIcon /></div>
              <div className="cursor-pointer" onClick={nextImage2}><NextIcon /></div>
            </div>
          </div>

          <div className="flex overflow-x-auto space-x-4 p-4">
            {imageDisplay2.map((src, index) => (
              <div key={index} className="bg-white shadow-md flex-none p-2 m-2 flex flex-col items-center" style={{ width: '256px', height: '256px' }}>
                <img src={src} className="mb-2 w-full h-full object-cover" />
                <p className="text-center font-bold"><br></br>{descriptionDisplay2[index]}</p>
              </div>
            ))}
          </div>
        </div>
        <hr className="w-full border-gray-200 border-2 m-0" />
      </div>



      {/* array 3 */}
      <div className="flex flex-col w-full mt-8 items-center px-4">
        <div className="flex flex-col md:flex-row justify-center w-full max-w-7xl mx-auto">
          <div className="flex flex-col w-full md:w-1/3 p-4">
            <h2 className="text-2xl font-bold mb-4">Service Tools & Equipment</h2>
            <p className="mb-4">
              Maintaining proper tire inflation is not only crucial for extending the lifespan of the tire but also plays a significant role in vehicle safety and fuel efficiency. TECH's comprehensive range of Air Service Tools and Equipment is designed to ensure that tire inflation systems are functioning optimally...
            </p>
            <button
              onClick={() => navigate('service-tools')}
              className="w-full text-black font-semibold py-2 rounded border border-black cursor-pointer">
              BROWSE ALL
            </button>
            <div className="flex py-4 px-2">
              <div className="cursor-pointer mr-2" onClick={prevImage3}><PreviousIcon /></div>
              <div className="cursor-pointer" onClick={nextImage3}><NextIcon /></div>
            </div>
          </div>

          <div className="flex overflow-x-auto space-x-4 p-4">
            {imageDisplay3.map((src, index) => (
              <div key={index} className="bg-white shadow-md flex-none p-2 m-2 flex flex-col items-center" style={{ width: '256px', height: '256px' }}>
                <img src={src} className="mb-2 w-full h-full object-cover" />
                <p className="text-center font-bold"><br></br>{descriptionDisplay3[index]}</p>
              </div>
            ))}
          </div>
        </div>
        <hr className="w-full border-gray-200 border-2 m-0" />
      </div>



      {/* array 4 */}
      <div className="flex flex-col w-full mt-8 items-center px-4">
        <div className="flex flex-col md:flex-row justify-center w-full max-w-7xl mx-auto">
          <div className="flex flex-col w-full md:w-1/3 p-4">
            <h2 className="text-2xl font-bold mb-4">Wheel & Tire Supplies</h2>
            <p className="mb-4">
              Maintaining proper air pressure and balance in tires is crucial for vehicle safety, performance, and efficiency. TECH's comprehensive range of tire maintenance products, including valves, TPMS systems, and wheel balancing solutions, are designed to meet the stringent specifications...
            </p>
            <button
              onClick={() => navigate('supplies')}
              className="w-full text-black font-semibold py-2 rounded border border-black cursor-pointer">
              BROWSE ALL
            </button>
            <div className="flex py-4 px-2">
              <div className="cursor-pointer mr-2" onClick={prevImage4}><PreviousIcon /></div>
              <div className="cursor-pointer" onClick={nextImage4}><NextIcon /></div>
            </div>
          </div>

          <div className="flex overflow-x-auto space-x-4 p-4">
            {imageDisplay4.map((src, index) => (
              <div key={index} className="bg-white shadow-md flex-none p-2 m-2 flex flex-col items-center" style={{ width: '256px', height: '256px' }}>
                <img src={src} className="mb-2 w-full h-full object-cover" />
                <p className="text-center font-bold"><br></br>{descriptionDisplay4[index]}</p>
              </div>
            ))}
          </div>
        </div>
        <hr className="w-full border-gray-200 border-2 m-0" />
      </div>











    </motion.div >
  );
}






