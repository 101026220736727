import React, { createContext, useState, useContext, useEffect } from 'react';


const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cartProducts, setCartProducts] = useState(() => {
        return JSON.parse(localStorage.getItem('cart')) || [];
    });

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cartProducts));
    }, [cartProducts]);

    const getTotalProducts = () => {
        return cartProducts.reduce((acc, item) => acc + item.quantity, 0);
    };

    return (
        <CartContext.Provider value={{ cartProducts, setCartProducts, getTotalProducts }}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => useContext(CartContext);
