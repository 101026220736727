import React, { useState, useEffect, useContext, useRef } from 'react';
import { motion } from "framer-motion";
import { useRoutes, navigate } from "hookrouter";
import { CheckIcon, SavedIcon, NoDataIcon } from '../assets/icons/hiking';
import { Tooltip } from 'antd';
import Button from '@mui/material/Button';
import { Modal, Box, Typography, TextField, IconButton, Menu, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

export default function Saved() {
    const [products, setProducts] = useState([]);

    const [isModalOpen, setModalOpen] = useState(false);

    const [selectedProduct, setSelectedProduct] = useState({});
    useEffect(() => {
        const savedProducts = localStorage.getItem('savedProducts');
        if (savedProducts) {
            setProducts(JSON.parse(savedProducts));
        }
    }, []);

    const openModal = (product) => {
        setSelectedProduct({ ...product, initialCount: 1 });
        setCount(1);
        setModalOpen(true);
    };

    const handleClosePopup = () => {
        setSelectedUserDetails(null);
    };

    //counter
    const [count, setCount] = useState(1);

    const inputCounter = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value >= 0) {
            setCount(value);
        }
    };

    const increment = () => {
        setCount(prevCount => prevCount + 1);
    };

    const decrement = () => {
        setCount(prevCount => (prevCount > 1 ? prevCount - 1 : 1));
    };

    //update quantity from modal
    const updateQuantity = (productId, newCount) => {
        let cart = JSON.parse(localStorage.getItem('cart')) || [];
        const productIndex = cart.findIndex((item) => item.id === productId);

        if (productIndex !== -1) {
            cart[productIndex].quantity = newCount;
        }

        localStorage.setItem('cart', JSON.stringify(cart));
        setSelectedProduct(prev => ({ ...prev, initialCount: newCount }));

        window.parent.location = window.parent.location.href;
    };


    // add to cart
    const [selectedUserDetails, setSelectedUserDetails] = useState(null);

    const addToCartButton = (product) => {
        openModal(product);
        setSelectedUserDetails(true);
        handleAddToCart(product);
    };

    const handleAddToCart = (product) => {
        let cart = JSON.parse(localStorage.getItem('cart')) || [];

        const productIndex = cart.findIndex((item) => item.id === product.id);

        if (productIndex !== -1) {
            cart[productIndex].quantity += 1;
        } else {
            cart.push({ ...product, quantity: 1 });
        }

        localStorage.setItem('cart', JSON.stringify(cart));
    };

    //remove product (saved) from localStorage
    const removeProductFromLocalStorage = (sku) => {
        const updatedProducts = products.filter(product => product.sku !== sku);
        setProducts(updatedProducts);

        localStorage.setItem('savedProducts', JSON.stringify(updatedProducts));

        const collections = JSON.parse(localStorage.getItem('collections')) || [];

        const updatedCollections = collections.map(collection => {
            return {
                ...collection,
                skus: collection.skus.filter(collectionSku => collectionSku !== sku)
            };
        });

        localStorage.setItem('collections', JSON.stringify(updatedCollections));

        setCollections(updatedCollections);
        setFilteredProducts(updatedProducts);

    };




    function decodeHtml(html) {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    }

    function AddToCartPopup({ user, product, onClose }) {


        const decodedTitle = decodeHtml(product.title);
        const decodedDescription = decodeHtml(product.Description);


        const popupRef = useRef(null);

        const handleOutsideClick = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                onClose();
            }
        };


        return (
            <div
                className="fixed z-10 inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center"
                onClick={handleOutsideClick}
            >
                <div ref={popupRef} className="bg-white p-5 rounded-lg shadow-lg w-full max-w-4xl">
                    <div className=" py-4 flex items-center justify-center p-4 bg-green-100">
                        <CheckIcon />
                        <p className="font-bold text-green-600 ml-2 ">Product successfully added to your cart!</p>
                    </div>

                    <div className="flex px-4">
                        <img
                            src={product.image}
                            className="mb-4 md:mb-0 md:mr-4 max-w-full md:max-w-xs h-auto"
                            alt={product.title}
                        />

                        <div className="flex-1">
                            <p className="text-lg font-semibold mb-2 text-center md:text-left">{decodedTitle}</p>
                            <p className="text-base mb-4 text-center md:text-left">{decodedDescription}</p>

                            <div className="flex justify-between items-center mb-4">
                                <div className="flex items-center">
                                    <button className="px-3 py-1 border rounded" onClick={decrement}>-</button>
                                    <input
                                        className="px-3 py-1 border rounded text-center"
                                        style={{ width: '64px' }}
                                        type="text"
                                        value={count}
                                        onChange={inputCounter}
                                    />
                                    <button className="px-3 py-1 border rounded" onClick={increment}>+</button>

                                    {
                                        count !== selectedProduct.initialCount &&
                                        <button
                                            className="px-2 py-1 bg-yellow-500 text-white rounded text-xs ml-2"
                                            onClick={() => updateQuantity(selectedProduct.id, count)}
                                        >
                                            Update
                                        </button>
                                    }


                                </div>
                                <p className="font-bold">€{product.price}</p>
                            </div>

                            <hr className="my-6 border-gray-200 border-2" />

                            <div className="flex justify-between items-center">
                                <p className="font-bold">Total</p>
                                <p className="font-bold">€{product.price * count}</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex p-4">
                        <button className="py-2 px-4 font-semibold bg-black text-white rounded flex-1 mr-2" onClick={onClose}>Continue Shopping</button>
                        <button className="py-2 px-4 font-semibold bg-red-500 text-white rounded hover:bg-red-700 flex-1" onClick={() => navigate('cart')}>View Cart & Checkout</button>
                    </div>
                </div>
            </div>
        );
    }




    //Create a Collection
    const [open, setOpen] = useState(false);
    const [collectionName, setCollectionName] = useState('');
    const [error, setError] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleInputChange = (e) => {
        setCollectionName(e.target.value);
        if (e.target.value === '') {
            setError(true);
        } else {
            setError(false);
        }
    };


    //collections
    const [collections, setCollections] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
        const storedCollections = JSON.parse(localStorage.getItem('collections')) || [];
        setCollections(storedCollections);
    }, []);

    const [selectedIndex, setSelectedIndex] = useState('all');

    const handleSelect = (index) => {
        setSelectedIndex(index);

        if (index === 'all') {
            setFilteredProducts(products);
        } else {

            const selectedCollection = collections[index];
            const selectedSkus = selectedCollection.skus || [];

            const matchedProducts = products.filter(product => selectedSkus.includes(product.sku));

            setFilteredProducts(matchedProducts);
        }
    };



    // Collections (More button options)
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuIndex, setMenuIndex] = useState(null);

    const handleMenuClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setMenuIndex(index);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setMenuIndex(null);
    };

    const handleDelete = (index) => {
        const updatedCollections = collections.filter((_, i) => i !== index);

        setCollections(updatedCollections);

        localStorage.setItem('collections', JSON.stringify(updatedCollections));

        handleMenuClose();
        handleSelect('all');
    };


    const handleEdit = (index) => {
        setCurrentEditIndex(index);
        setEditName(collections[index].collectionName); // Asegúrate de acceder a collectionName
        setOpenEditDialog(true);
        handleMenuClose();
    };



    // edit collection name
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [editName, setEditName] = useState('');
    const [currentEditIndex, setCurrentEditIndex] = useState(null);


    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
    };

    const handleSaveEdit = () => {
        const updatedCollections = [...collections];
        updatedCollections[currentEditIndex] = {
            ...updatedCollections[currentEditIndex],
            collectionName: editName // Actualiza solo collectionName
        };

        setCollections(updatedCollections);
        localStorage.setItem('collections', JSON.stringify(updatedCollections));
        setOpenEditDialog(false);
    };



    // submit the skus
    const [openDialog, setOpenDialog] = useState(false);
    const [skuInputs, setSkuInputs] = useState(Array(5).fill(''));
    const [selectedSkus, setSelectedSkus] = useState([]);

    const handleOpenDialog = () => {
        if (collectionName !== '') {
            setOpenDialog(true);
        } else {
            setError(true);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleCheckboxChange = (sku, checked) => {
        if (checked) {
            setSelectedSkus(prev => [...prev, sku]);
        } else {
            setSelectedSkus(prev => prev.filter(item => item !== sku));
        }
    };

    const handleFinish = () => {
        if (collectionName !== '') {
            const collections = JSON.parse(localStorage.getItem('collections')) || [];

            collections.push({
                collectionName: collectionName,
                skus: selectedSkus
            });

            localStorage.setItem('collections', JSON.stringify(collections));

            handleClose();
            handleCloseDialog();
            setCollectionName('');
            setCollections(collections);
            setSkuInputs(Array(5).fill(''));
            setSelectedSkus([]);
        } else {
            setError(true);
        }
    };




    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}

        >


            <div className="flex justify-start py-5">
                <div className="flex justify-between items-center w-full px-14">
                    <span className="text-base font-semibold">My Saved Items</span>

                    <div className="flex space-x-3">
                        <Button
                            variant="outlined"
                            className="px-4 py-2 border-white text-black "
                            style={{
                                borderColor: 'black',
                                color: 'black',
                                padding: '8px 16px',
                                fontWeight: 550,
                            }}
                        >
                            Select & Add
                        </Button>

                        <Button
                            variant="contained"
                            className="px-4 py-2 bg-black text-white font-semibold"
                            style={{
                                backgroundColor: 'black',
                                color: 'white',
                                padding: '8px 16px',
                                fontWeight: 600,
                            }}
                        >
                            Add All to Cart
                        </Button>
                    </div>

                </div>
            </div>
            <hr className="border-gray-200 border-1 mx-14" />


            <div className="flex p-4 mx-14 mt-3">
                <div className="flex justify-start m-1">
                    <div className="flex min-h-screen">
                        <div style={{ width: '250px' }}>

                            <ul className="pt-2 space-y-3">
                                <li className="w-full">
                                    <Button
                                        variant="text"
                                        className={`w-full px-4 py-3 font-semibold text-lg ${selectedIndex === 'all' ? 'bg-gray-200' : ''}`}
                                        style={{
                                            borderBottom: '1px solid #E0E0E0',
                                            justifyContent: 'flex-start',
                                            color: "#757373",
                                            backgroundColor: selectedIndex === 'all' ? '#E0E0E0' : '',
                                        }}
                                        onClick={() => handleSelect('all')}
                                    >
                                        ALL Products
                                    </Button>
                                </li>


                                {collections.map((collection, index) => (
                                    <li key={index} className="w-full relative">
                                        <Button
                                            variant="text"
                                            className={`w-full px-4 py-3 font-semibold text-lg flex items-center ${selectedIndex === index ? 'bg-gray-200' : ''}`}
                                            style={{
                                                borderBottom: '1px solid #E0E0E0',
                                                justifyContent: 'space-between',
                                                backgroundColor: selectedIndex === index ? '#E0E0E0' : '',
                                                // textTransform: 'none', avoids the texts in caps
                                            }}
                                            onClick={() => handleSelect(index)}
                                        >
                                            <span className="flex-grow text-left">{collection.collectionName}</span>
                                        </Button>

                                        <IconButton
                                            onClick={(event) => handleMenuClick(event, index)}
                                            style={{ position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)' }}
                                        >
                                            <MoreHorizIcon />
                                        </IconButton>

                                        <Menu
                                            anchorEl={anchorEl}
                                            open={menuIndex === index}
                                            onClose={handleMenuClose}
                                            anchorOrigin={{
                                                vertical: 'buttom',
                                                horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 'buttom',
                                                horizontal: 'left',
                                            }}
                                            style={{ zIndex: 1300 }}
                                        >
                                            <MenuItem onClick={() => handleDelete(index)}>
                                                <DeleteIcon fontSize="small" />
                                                <span style={{ marginLeft: '8px' }}>Delete</span>
                                            </MenuItem>
                                            <MenuItem onClick={() => handleEdit(index)}>
                                                <EditIcon fontSize="small" />
                                                <span style={{ marginLeft: '8px' }}>Edit Name</span>
                                            </MenuItem>
                                        </Menu>
                                    </li>
                                ))}

                                {/* popup to edit collection */}
                                <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
                                    <DialogTitle>Edit Collection Name</DialogTitle>
                                    <DialogContent>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            type="text"
                                            fullWidth
                                            value={editName}
                                            onChange={(e) => setEditName(e.target.value)}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    color: 'black',
                                                    '& fieldset': {
                                                        borderColor: 'gray',
                                                        borderWidth: '0.5px',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: 'gray',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'gray',
                                                        borderWidth: '0.5px',
                                                    },
                                                },
                                                '& .MuiInputBase-input': {
                                                    color: 'black',
                                                    padding: '9px 15px',
                                                    fontSize: '14px',
                                                },
                                                '& .MuiOutlinedInput-root.Mui-focused': {
                                                    outline: 'none',
                                                    boxShadow: 'none',
                                                },
                                                '& input': {
                                                    '&:focus': {
                                                        outline: 'none',
                                                        boxShadow: 'none',
                                                    },
                                                },
                                            }}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseEditDialog} color="primary"
                                            style={{
                                                color: "black"
                                            }}>
                                            Cancel
                                        </Button>
                                        <Button onClick={handleSaveEdit} color="primary" style={{ color: "black" }}>
                                            Save
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                            </ul>

                            <div className="pt-5">
                                <Button
                                    variant="outlined"
                                    sx={{
                                        width: '100%',
                                        padding: '12px',
                                        borderColor: 'black',
                                        color: 'black',
                                        '&:hover': {
                                            borderColor: 'gray',
                                            backgroundColor: '#f5f5f5',
                                        },
                                    }}
                                    onClick={handleOpen}
                                >
                                    <AddIcon />
                                    Create a Collection
                                </Button>

                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-title"
                                    aria-describedby="modal-description"
                                >
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            width: 400,
                                            bgcolor: 'background.paper',
                                            borderRadius: '10px',
                                            boxShadow: 24,
                                            p: 3,
                                            outline: 'none',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                paddingBottom: 1.5,
                                            }}
                                        >
                                            <Typography
                                                id="modal-title"
                                                variant="subtitle1"
                                                component="h2"
                                                sx={{
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                New Collection
                                            </Typography>
                                            <IconButton
                                                aria-label="close"
                                                onClick={handleClose}
                                                sx={{
                                                    position: 'absolute',
                                                    right: 0,
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Box>

                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            placeholder="Collection Name"
                                            autoComplete="off"
                                            value={collectionName}
                                            onChange={handleInputChange}
                                            sx={{


                                                '& .MuiOutlinedInput-root': {

                                                    color: 'black',
                                                    '& fieldset': {
                                                        borderColor: 'gray',
                                                        borderWidth: '0.5px',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: 'gray',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'gray',
                                                        borderWidth: '0.5px',
                                                    },
                                                },
                                                '& .MuiInputBase-input': {
                                                    color: 'black',
                                                    padding: '9px 15px',
                                                    fontSize: '14px',
                                                },
                                                '& .MuiOutlinedInput-root.Mui-focused': {
                                                    outline: 'none',
                                                    boxShadow: 'none',
                                                },
                                                '& input': {
                                                    '&:focus': {
                                                        outline: 'none',
                                                        boxShadow: 'none',
                                                    },
                                                },
                                            }}
                                        />

                                        <Button
                                            variant="contained"
                                            fullWidth
                                            onClick={handleOpenDialog}
                                            sx={{
                                                marginTop: 1.5,
                                                backgroundColor: 'black',
                                                color: 'white',

                                                fontWeight: 600,
                                                '&:hover': {
                                                    backgroundColor: '#333',
                                                },
                                                '&:disabled': {
                                                    backgroundColor: '#e0e0e0',
                                                    color: '#9e9e9e',
                                                },
                                            }}
                                            disabled={collectionName === ''}
                                        >
                                            Next
                                        </Button>
                                    </Box>



                                </Modal>
                            </div>

                            <Dialog open={openDialog} onClose={handleCloseDialog}>
                                <DialogTitle>Add from saved</DialogTitle>
                                <DialogContent className="max-h-96 overflow-y-auto">
                                    {products.map((product, index) => (
                                        <div key={index} className="flex flex-col gap-5">
                                            <div className="flex items-center justify-between border-b border-gray-300 py-2">
                                                <Checkbox
                                                    className="mr-5"
                                                    onChange={(e) => handleCheckboxChange(product.sku, e.target.checked)}
                                                />
                                                <img src={process.env.PUBLIC_URL + product.image} alt={product.title} className="w-24 h-auto mr-5" />
                                                <div className="flex-1 mr-5">
                                                    <span className="block font-bold text-lg truncate max-w-xs">{product.title}</span>
                                                    <span className="block text-sm text-gray-500 truncate max-w-xs">{product.description}</span>
                                                </div>
                                                <div>
                                                    <span className="font-bold text-base text-gray-700">€{product.price}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={handleCloseDialog}
                                        variant="outlined"
                                        className="px-4 py-2 border-white text-black "
                                        style={{
                                            borderColor: 'black',
                                            color: 'black',
                                            padding: '8px 16px',
                                            fontWeight: 550,
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={handleFinish}
                                        variant="contained"
                                        className="px-4 py-2 font-semibold"
                                        style={{
                                            backgroundColor: selectedSkus.length === 0 ? 'gray' : 'black',
                                            color: selectedSkus.length === 0 ? 'white' : 'white',
                                            padding: '8px 16px',
                                            fontWeight: 600,
                                        }}
                                        disabled={selectedSkus.length === 0}
                                    >
                                        Create Collection
                                    </Button>

                                </DialogActions>
                            </Dialog>

                        </div>
                    </div>
                </div>
                {products.length > 0 ? (
                    <div className="grid grid-cols-3 gap-4 mr-5 w-full">
                        {(filteredProducts.length > 0 ? filteredProducts : products).map((product, index) => (

                            <div key={index} style={{ textAlign: 'center' }}>
                                <div
                                    className="blur-on-hover relative"
                                    style={{ backgroundColor: 'rgba(255, 255, 255)', width: '264px', height: '307px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}
                                >
                                    <img src={process.env.PUBLIC_URL + product.image} style={{ maxWidth: '220px' }} />

                                    <div style={{ position: 'absolute', bottom: '10px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 10px', boxSizing: 'border-box' }}>
                                        <div style={{ borderTop: '1px solid #ccc', width: '100%', display: 'flex', justifyContent: 'space-between', position: 'relative', paddingTop: '5px' }}>
                                            <span style={{ fontSize: '14px', fontWeight: 'bold', padding: '0 5px' }}>{product.sku}</span>
                                            <span style={{ fontSize: '14px', fontWeight: 'bold', padding: '0 5px' }}>{product.item_number}</span>
                                            <div style={{ position: 'absolute', top: '0', bottom: '0', left: '50%', width: '1px', backgroundColor: '#ccc', transform: 'translateX(-50%)' }}></div>
                                        </div>
                                    </div>

                                    <button
                                        className="add-to-cart-button font-semibold opacity-0 text-sm"
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            zIndex: 2
                                        }}
                                        onClick={() => addToCartButton({
                                            id: product.id,
                                            title: product.title,
                                            image: `${process.env.PUBLIC_URL + product.image}`,
                                            price: product.price
                                        })}
                                    >
                                        Add to Cart
                                    </button>
                                </div>
                                <div style={{ textAlign: 'left', marginTop: '10px', width: '264px', margin: 'auto' }}>
                                    <span className="font-semibold text-sm block truncate">{product.title}</span>
                                    <span className="font-medium text-sm block truncate">{product.description}</span>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span style={{ fontWeight: '700', fontSize: '20px' }}>€{product.price}</span>
                                        <Tooltip title="Unsave Item" placement="bottom" arrow>
                                            <div className="cursor-pointer"
                                                onClick={() => removeProductFromLocalStorage(product.sku)}
                                            >
                                                <SavedIcon />
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {selectedUserDetails && (
                            <AddToCartPopup product={selectedProduct} onClose={handleClosePopup} />
                        )}
                    </div>

                ) : (
                    <div className="flex flex-col w-full items-center pt-14">
                        <div className="flex flex-col items-center">
                            <span className="text-xl font-semibold">No products saved.</span>
                        </div>
                        <NoDataIcon />
                    </div>
                )}



            </div >
        </motion.div >

    );

}

