import React, { useState } from 'react';
import { motion } from "framer-motion";

export default function Terms() {

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex flex-col justify-center items-center pt-4"
        >


            {/*tos*/}
            <div className="flex flex-col w-full mt-8 items-center px-4 sm:px-6 lg:px-8">
                <div className="flex justify-center w-full max-w-7xl mx-auto">
                    <article>
                        <header>
                            <h1 className="text-2xl font-bold mb-4">Terms &amp; Conditions of Use</h1>
                        </header>
                        <div>
                            <p>These are the terms and conditions of use for techeurope.com, products.techeurope.com and en.techeurope.com, please read them carefully. In these terms and conditions ‘we, our, us’ refers to TECH Europe &amp; the aforementioned websites.</p><br></br>
                            <p><strong>Acceptance of Terms</strong></p>
                            <p>By accessing our websites and the information contained within them, you agree to be bound by these terms and conditions and you accept our <a href="https://en.techeurope.com/cookie-policy/">Privacy &amp; Cookie Statement</a>. If you object to any of the terms and conditions set out in this agreement you should not use any of the products or services on our websites.</p><br></br>
                            <p>You agree that you shall not use the website for illegal purposes and will respect all applicable laws and regulations. You agree not to use the website in a way that may impair the performance, corrupt the content, or otherwise reduce the overall functionality of the website. You also agree not to compromise the security of the website or attempt to gain access to secured areas or sensitive information. You agree to be fully responsible for any claim, expense, liability, losses, costs including legal fees incurred by us arising from any infringement of the terms and conditions set out in this agreement.</p><br></br>
                            <p><strong>Conditions of Use</strong></p>
                            <p>Our website is currently set up to service and accept orders from Belgium, The Netherlands, and Luxembourg. All orders must reach a minimum order value of €100. All products on our website are displayed and charged in € Euro. Appropriate VAT will be calculated at checkout and all accounts require a valid VAT number upon creation. TECH Europe reserves the right to amend pricing and adjust the products available for sale. Should TECH Europe introduce any promotional codes, only one will be valid for use per transaction.</p><br></br>
                            <p>The products featured on our website have been carefully selected. Items featured on the site are held in stock, and every effort will be made to complete an order to customer requirement. If an item sells out, it will be temporarily removed from the website and made available once stock is back in place. If items that you order should be out of stock, or delayed for any reason, we will try to contact you at the email address you provided when placing your order. If we cannot contact you, or receive no response to our email, we will continue to process the remaining items on your order. The customer’s statutory rights are unaffected. These terms and conditions, and all matters connected with any order you place on our website, are governed by European law.</p><br></br>
                            <p>No contract will exist between you and our company for the sale by it to you of any product unless and until your order is confirmed and paid. That acceptance will be deemed complete and will be deemed for all purposes to have been effectively communicated to you at the time your order confirmation is received. We may not accept your order for reasons such as in cases where we may have been unable to obtain authorization for your payment, product availability, a pricing or product description error or there may have been a failure to meet the eligibility to order criteria as set out in our terms and conditions.</p><br></br>
                            <p><strong>Payment</strong></p>
                            <p>WorldPay is used to process payments. To view the WorldPay user agreement and learn how your payment is processed, please click here.</p><br></br>
                            <p><strong>Delivery &amp; Returns</strong></p>
                            <p>Orders are typically dispatched within 2-3 working days of the order being placed. TECH Europe can currently only accept orders with a delivery address to Belgium, The Netherlands and Luxembourg. Any updates to these countries will be posted here. Shipping will be charged at a flat rate of €12 with free shipping on orders over €200. A minimum order value of €100 is applicable to all orders.</p><br></br>
                            <p>We hope you will be happy with your purchase and can only accept returns where a fault has occurred, for example, in the unlikely event that your order has arrived damaged. In this instance, please email <a href="mailto:ecomm.emea@trc4r.com">ecomm.emea@trc4r.com</a>. Once we receive your item, we will inspect it and we will notify you if your refund has been approved. Upon approval of your return, we will initiate a refund to your original method of payment. You should receive your refund within a few days of it being processed, however this will depend on your card issuer’s policies.</p><br></br>
                            <p><strong>Modification</strong></p>
                            <p>TECH Europe reserves the right to change any part of this agreement without notice and your use of the website will be deemed as acceptance of this agreement. We advise users to regularly check the terms and conditions of this agreement as any updates will be posted here.</p><br></br>
                            <p>TECH Europe has complete discretion to modify, edit, delete, or remove any part of this site and/or any information, products, or services available within the site without warning or liability arising from such action.</p><br></br>
                            <p>TECH Europe will under no circumstance be liable for indirect, incidental, special, or consequential damages including any loss of business, revenue, profits, or data in relation to your use of the website or the use of any website that you may gain access to from our websites.</p><br></br>
                            <p>TECH Europe will use reasonable endeavours to verify the accuracy of any information on the website but makes no representation or warranty of any kind express or implied statutory or otherwise regarding the contents or availability of the sites or that it will be timely or error free, that defects will be corrected, or that the site or the server that makes it available are free of viruses or bugs or represents the full functionality, accuracy, reliability of the site. We will not be in any way liable for any material or data sent or received or not sent or received or for any of the above-mentioned damages that result from the use or inability to use the products for sale through this site.</p><br></br>
                            <p><strong>Copyright</strong></p>
                            <p>All intellectual property of the mentioned TECH Europe websites such as trademarks, trade names, patents, registered designs, and any other automatic intellectual property rights derived from the aesthetics or functionality of the website remain the property of our company.</p><br></br>
                            <p>By using the websites, you agree to respect the intellectual property rights of them and will refrain from copying, downloading, transmitting, reproducing, printing, or exploiting for commercial purpose any material contained within the website.</p><br></br>
                            <p><strong>Third Parties</strong></p>
                            <p>The website may contain hyperlinks to websites operated by other parties. We do not control such websites and we take no responsibility for, and will not incur any liability in respect of, their content. Our inclusion of hyperlinks to such websites does not imply any endorsement of views, statements or information contained in such websites.</p><br></br>
                            <p><strong>Privacy Policy</strong></p>
                            <p>TECH Europe is committed to protecting the privacy of its customers. <a href="https://en.techeurope.com/cookie-policy/"><strong>Click here</strong></a> to view our full privacy &amp; cookies statement.</p><br></br>
                            <p><strong>Contact Us</strong></p>
                            <p>If you require further information on any of our products or the use of our websites, please do not hesitate to contact us:</p><br></br>
                            <p>Email: ecomm.emea@trc4r.com</p>
                            <p>Phone: +32 14 423103</p>
                            <p>Mail: TECH Europe, Koeybleuken 16, 2300 Turnhout, Belgium</p><br></br><br></br>
                        </div>
                    </article>
                </div>
            </div>
        </motion.div>
    );
}
