import React, { useState, useRef, useEffect, useContext } from 'react';
import { motion } from "framer-motion";
import { SearchContext } from './SearchContext';
import { useRoutes, navigate } from "hookrouter";

import { SavedIcon, CheckIcon, FullViewIcon, SaveIcon } from '../assets/icons/hiking';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';


export default function Supplies() {

    const [products, setProducts] = useState([]);
    const [isLoadingProducts, setIsLoadingProducts] = useState(false);

    const { searchTerm } = useContext(SearchContext);


    useEffect(() => {
        //    "category_id": "Wheel and Tire Supplies",


        const eid = localStorage.getItem("default");
        const nonce = localStorage.getItem("nonce");

        const type = "product";


        function protocolpluspath() {
            return window.location.protocol + "//" + window.location.host + "/";
        }

        setIsLoadingProducts(true);

        const url = protocolpluspath() + "admin/getData.php?eid=" + eid + "&type=" + type + "&search=" + searchTerm + "&nonce=" + nonce + "&filter=";

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    // only category_id
                    const filteredProducts = data.data.response.filter(product => product.identifiers.category_id === "Wheel and Tire Supplies");
                    setProducts(filteredProducts);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setProducts([]);
            })
            .finally(() => {
                setIsLoadingProducts(false);
            });
    }, [searchTerm]);



    const [isModalOpen, setModalOpen] = useState(false);

    const [selectedProduct, setSelectedProduct] = useState({});

    const openModal = (product) => {
        setSelectedProduct({ ...product, initialCount: 1 });
        setCount(1);
        setModalOpen(true);
    };


    const [selectedUserDetails, setSelectedUserDetails] = useState(null);

    const handleClosePopup = () => {
        setModalOpen(false);
        setSelectedProduct(null);
    };

    //counter 
    const [count, setCount] = useState(1);

    const inputCounter = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value >= 0) {
            setCount(value);
        }
    };
    const increment = () => {
        setCount(prevCount => prevCount + 1);
    };

    const decrement = () => {
        setCount(prevCount => (prevCount > 1 ? prevCount - 1 : 1));
    };


    // full view side-bar
    const [isExpanded, setIsExpanded] = useState(true);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    // add to cart function ( save product in the localStorage)
    const handleAddToCart = (product) => {
        let cart = JSON.parse(localStorage.getItem('cart')) || [];

        const productIndex = cart.findIndex((item) => item.id === product.id);

        if (productIndex !== -1) {
            cart[productIndex].quantity += 1;
        } else {
            cart.push({ ...product, quantity: 1 });
        }

        localStorage.setItem('cart', JSON.stringify(cart));
    };

    const addToCartButton = (product) => {
        openModal(product);
        handleAddToCart(product);
    };

    const updateQuantity = (productId, newCount) => {
        let cart = JSON.parse(localStorage.getItem('cart')) || [];
        const productIndex = cart.findIndex((item) => item.id === productId);

        if (productIndex !== -1) {
            cart[productIndex].quantity = newCount;
        }

        localStorage.setItem('cart', JSON.stringify(cart));
        setSelectedProduct(prev => ({ ...prev, initialCount: newCount }));

        window.parent.location = window.parent.location.href;
    };


    //save product
    const [savedProducts, setSavedProducts] = useState([]);
    const [savedNotificationProductId, setSavedNotificationProductId] = useState(null);

    useEffect(() => {
        const products = JSON.parse(localStorage.getItem('savedProducts')) || [];
        setSavedProducts(products);
    }, []);

    function saveOrRemoveProductFromLocalStorage(product) {
        let products = [...savedProducts];
        const productIndex = products.findIndex((item) => item.id === product.id);
        let isAdding = productIndex === -1;

        if (isAdding) {
            products.push(product);
        } else {
            products.splice(productIndex, 1);
        }

        localStorage.setItem('savedProducts', JSON.stringify(products));
        setSavedProducts(products);
        return isAdding;
    }



    function isProductSaved(productId) {
        return savedProducts.some(product => product.id === productId);
    }


    const handleProductSave = (product) => {
        const isAdding = saveOrRemoveProductFromLocalStorage(product);
        if (isAdding) {
            setSavedNotificationProductId(product.id);
            setTimeout(() => setSavedNotificationProductId(null), 1000);
        }
    };


    function decodeHtml(html) {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    }


    function AddToCartPopup({ product, onClose, count, increment, decrement, inputCounter, updateQuantity }) {

        const decodedTitle = decodeHtml(product.title);
        const decodedDescription = decodeHtml(product.Description);

        return (
            <div className="fixed z-10 inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
                <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-4xl">
                    <div className=" py-4 flex items-center justify-center p-4 bg-green-100">
                        <CheckIcon />
                        <p className="font-bold text-green-600 ml-2">Product successfully added to your cart!</p>
                    </div>

                    <div className="flex px-4">
                        <img src={product.image} className="mr-4 max-w-xs h-auto" alt={product.title} />

                        <div className="flex-1">

                            <p className="text-lg font-semibold mb-2">{decodedTitle}</p>
                            <p className="text-base mb-4">{decodedDescription}</p>


                            <div className="flex justify-between items-center mb-4">
                                <div className="flex items-center">
                                    <button className="px-3 py-1 border rounded" onClick={decrement}>-</button>
                                    <input
                                        className="px-3 py-1 border rounded text-center"
                                        style={{ width: '64px' }}
                                        type="text"
                                        value={count}
                                        onChange={inputCounter}
                                    />
                                    <button className="px-3 py-1 border rounded" onClick={increment}>+</button>

                                    {
                                        count !== product.initialCount &&
                                        <button
                                            className="px-2 py-1 bg-yellow-500 text-white rounded text-xs ml-2"
                                            onClick={() => updateQuantity(product.id, count)}
                                        >
                                            Update
                                        </button>
                                    }
                                </div>
                                <p className="font-bold">€{product.price}</p>
                            </div>

                            <hr className="my-6 border-gray-200 border-2" />

                            <div className="flex justify-between items-center">
                                <p className="font-bold">Total</p>
                                <p className="font-bold">€{product.price * count}</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex p-4">
                        <button className="py-2 px-4 font-semibold bg-black text-white rounded flex-1 mr-2" onClick={onClose}>Continue Shopping</button>
                        <button className="py-2 px-4 font-semibold bg-red-500 text-white rounded hover:bg-red-700 flex-1" onClick={() => navigate('cart')}>View Cart & Checkout</button>
                    </div>
                </div>
            </div>
        );
    }




    // tag logic
    const [filters, setFilters] = useState({
        all: true,
        Valves: false,
        Rings: false,
        Balancing: false
    });

    useEffect(() => {
        // Fetch products here and setProducts
    }, []);

    const handleFilterChange = (event) => {
        const { id, checked } = event.target;
        setFilters((prevFilters) => {
            if (id === 'all') {
                // If "All" is selected, deselect all other filters
                return {
                    all: true,
                    Valves: false,
                    Rings: false,
                    Balancing: false
                };
            } else {
                // If another filter is selected, deselect "All"
                return {
                    ...prevFilters,
                    all: false,
                    [id]: checked
                };
            }
        });
    };

    const filteredProducts = products.filter((product) => {
        if (filters.all) return true;
        if (filters.Valves && product.product.tags.includes('Valves and Valve Hardware')) return true;
        if (filters.Rings && product.product.tags.includes('O and L Rings')) return true;
        if (filters.Balancing && product.product.tags.includes('Wheel Balancing')) return true;
        return false;
    });



    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className="flex">



                <div className="flex justify-start m-1 hidden sm:inline">
                    <div className="flex min-h-screen">
                        <div className={`${isExpanded ? 'w-[250px]' : 'w-[25px]'}`}>
                            <ul className="pt-5">
                                <li className="flex justify-between items-center text-sm font-semibold">
                                    {isExpanded && <span className="px-2">Filter By</span>}
                                    <div className="cursor-pointer m-4" onClick={toggleExpansion}>
                                        <FullViewIcon />
                                    </div>
                                </li>
                                {isExpanded && <hr className="my-6 border-gray-200 border-1" />}
                                {isExpanded && (
                                    <div className="px-2">
                                        <div className="flex items-center my-4">
                                            <label htmlFor="all" className="flex-grow">All</label>
                                            <input
                                                type="checkbox"
                                                id="all"
                                                className="ml-2 form-checkbox h-4 w-4 text-black border-gray-300 rounded-sm"
                                                checked={filters.all}
                                                onChange={handleFilterChange}
                                            />
                                        </div>
                                        <div className="flex items-center my-4">
                                            <label htmlFor="Valves" className="flex-grow">Valves and Valve Hardware</label>
                                            <input
                                                type="checkbox"
                                                id="Valves"
                                                className="ml-2 form-checkbox h-4 w-4 text-black border-gray-300 rounded-sm"
                                                checked={filters.Valves}
                                                onChange={handleFilterChange}
                                            />
                                        </div>
                                        <div className="flex items-center my-4">
                                            <label htmlFor="Rings" className="flex-grow">O and L Rings</label>
                                            <input
                                                type="checkbox"
                                                id="Rings"
                                                className="ml-2 form-checkbox h-4 w-4 text-black border-gray-300 rounded-sm"
                                                checked={filters.Rings}
                                                onChange={handleFilterChange}
                                            />
                                        </div>
                                        <div className="flex items-center my-4">
                                            <label htmlFor="Balancing" className="flex-grow">Wheel Balancing</label>
                                            <input
                                                type="checkbox"
                                                id="Balancing"
                                                className="ml-2 form-checkbox h-4 w-4 text-black border-gray-300 rounded-sm"
                                                checked={filters.Balancing}
                                                onChange={handleFilterChange}
                                            />
                                        </div>
                                    </div>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>

                {isLoadingProducts ? (
                    <Grid container spacing={4}>
                        {Array.from(new Array(20)).map((_, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                <Box sx={{ width: '100%', padding: 2 }}>
                                    <Skeleton variant="rectangular" width="100%" height={300} />
                                    <Box sx={{ pt: 0.5 }}>
                                        <Skeleton />
                                        <Skeleton width="60%" />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Skeleton width="15%" />
                                            <Skeleton width="15%" />
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <div className="grid grid-cols-4 gap-4 mr-5 w-full">
                        {Array.isArray(filteredProducts) && filteredProducts.map((product) => {
                            const productImage = product.images && product.images.images_primary;
                            const productTitle = product.product && decodeHtml(product.product.title);
                            const productShortDescription = product.product && decodeHtml(product.product.short_description);
                            const productPrice = product.properties && product.properties.use_price;

                            return (
                                <div key={product.id} style={{ textAlign: 'center' }}>
                                    <div
                                        className="blur-on-hover relative"
                                        style={{ backgroundColor: 'rgba(255, 255, 255)', width: '264px', height: '307px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}
                                    >
                                        <img src={productImage} alt={productTitle} style={{ maxWidth: '220px' }} />

                                        <button
                                            className="add-to-cart-button font-semibold opacity-0 text-sm"
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                zIndex: 2
                                            }}
                                            onClick={() => addToCartButton({
                                                id: product.id,
                                                title: productTitle,
                                                Description: productShortDescription,
                                                image: productImage,
                                                price: productPrice,
                                                initialCount: 1
                                            })}
                                        >
                                            Add to Cart
                                        </button>
                                    </div>

                                    <div style={{ textAlign: 'left', marginTop: '10px', width: '264px', margin: 'auto' }}>
                                        <span style={{ fontWeight: '500', fontSize: '12px', display: 'block' }}>{productTitle}</span>
                                        <span style={{ fontWeight: '500', fontSize: '12px', display: 'block' }}>{productShortDescription}</span>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <span style={{ fontWeight: '700', fontSize: '20px' }}>€{productPrice}</span>
                                            <div className="cursor-pointer" style={{ position: 'relative' }}
                                                onClick={() => handleProductSave({
                                                    id: product.id,
                                                    title: productTitle,
                                                    image: productImage,
                                                    price: productPrice
                                                })}>
                                                {isProductSaved(product.id) ? <SavedIcon /> : <SaveIcon />}
                                                {
                                                    savedNotificationProductId === product.id && (
                                                        <div style={{ position: 'absolute', top: '-30px', right: '0px', backgroundColor: 'black', color: 'white', padding: '2px 4px', borderRadius: '5px', zIndex: '50', fontSize: '12px' }}>
                                                            Saved
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        {isModalOpen && selectedProduct && (
                            <AddToCartPopup
                                product={selectedProduct}
                                onClose={handleClosePopup}
                                count={count}
                                increment={increment}
                                decrement={decrement}
                                inputCounter={inputCounter}
                                updateQuantity={updateQuantity}
                            />
                        )}
                    </div>


                )}


            </div>
        </motion.div >
    );
}







